const questionnaireMixins = {
  data() {
    return {
      showBottomButtons: false,
      answers: null,
    }
  },
  computed: {
    questionnaireCompleted() {
      if (!this.answers) return false

      let completed = true
      for (const key in this.answers) {
        if (Object.hasOwnProperty.call(this.answers, key)) {
          const answer = this.answers[key]
          if (!answer || !answer.length) {
            completed = false
            break
          }
        }
      }
      return completed
    },
  },
  methods: {
    convertAnswersToArrayOnly() {
      const copy = JSON.parse(JSON.stringify(this.answers))
      for (const key in copy) {
        if (Object.hasOwnProperty.call(copy, key)) {
          const answer = copy[key]
          if (!Array.isArray(answer)) copy[key] = [answer]
        }
      }
      return copy
    },
    questionnaireOnLoad(answers) {
      this.syncAnswersWithQuestComp(answers)
      this.showBottomButtons = true
    },
    questionnaireOnChange(answers) {
      this.syncAnswersWithQuestComp(answers)
    },
    syncAnswersWithQuestComp(answers) {
      this.answers = answers
    },
  }
}
export default questionnaireMixins
