<template>
  <div class="is-relative">
    <div
      class="each-question"
      v-for="(question) in questions"
      :key="question.key"
      :id="question.key"
      v-show="questionToggleStatusList[question.key]"
    >
      <div
        :class="`${question.type === 'toggle' ? 'is-flex-direction-row is-justify-content-space-between' : 'is-flex-direction-column'}`"
        class="question-wrapper is-flex mb-4"
      >
        <div
          class="question-name has-text-weight-semibold pb-2"
          :class="{ 'pr-4': question.type === 'toggle'}"
        >
          {{ question.question }}
          <v-popover class="is-inline">
            <img src="@assets/icons/info.svg" class="is-clickable tooltip-info tooltip-target" alt="tooltip" v-if="!!question.tooltip">
            <template v-slot:popover v-if="!!question.tooltip">
              <div class="has-text-white">
                {{ question.tooltip }}
              </div>
            </template>
          </v-popover>
        </div>
        <!-- <InputInTwoDecimals deprecated
          :scrollAnchorId="question.key"
          :initialValue="array2string(question.answer)"
          :pageId="pageId"
          @onInput="(value) => {onInput(value, question.key)}"
          currency="USD"
          v-if="question.type === 'number_input'"
        ></InputInTwoDecimals> -->
        <input
          class="input is-medium-height"
          v-model.trim="answers[question.key]"
          :placeholder="question.placeholder"
          @input="onValueChange(question)"
          v-if="question.type === 'text_input'"
        >
        <b-switch
          v-model="answers[question.key]"
          true-value="Yes"
          false-value="No"
          v-else-if="question.type === 'toggle'">
          {{ answers[question.key] }}
        </b-switch>
        <multiselect
          v-model="answers[question.key]"
          @input="onValueChange(question)"
          :options="question.options"
          :multiple="question.type === 'multi_select'"
          :close-on-select="question.type !== 'multi_select'"
          :searchable="question.type === 'multi_choice_searchable'"
          placeholder=" "
          v-else
        ></multiselect>
      </div>
    </div>
  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
// import InputInTwoDecimals from '@components/input/currency-input.vue'
import { fetchQuestionnaire } from '@api/profile'
import { VPopover } from 'v-tooltip'

export default {
  data() {
    return {
      questions: [],
      answers: {},
      questionToggleStatusList: {},
      dependenciesList: {},
    }
  },
  props: {
    onLoad: {
      type: Function,
      default: () => {}
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    pageId: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'suitability'
    },
  },
  components: {
    Multiselect,
    VPopover,
    // InputInTwoDecimals,
  },
  created() {
    this.initQuestionnaire()
  },
  methods: {
    initQuestionnaire() {
      fetchQuestionnaire().then((res) => {
        if (!res.success) return

        const data = res.data[this.type] || res.data
        this.questions = data
        this.answers = this.prepareStateToStoreAnswers(data)
        this.onLoad(this.answers)
      }) // wait for answers list
        .then(() => this.prepareToggleList(this.questions))
    },
    prepareStateToStoreAnswers(data) {
      const answers = {}
      data.forEach((question) => {
        // if questions with answers or without condition add to answers list
        if (!question.conditions || question.answer) answers[question.key] = this.getAnswersFromQuestion(question)
        // if question has condition(s) it is suitability questionnaire, add to dependencies list
        if (question.conditions) this.addDependenciesToList(question.key, question.conditions)
        if (question.optional) this.addDependenciesToList(question.key, question.optional)
      })
      return answers
    },
    // TODO: need another approach to include AND/OR conditions
    // TODO: need to optimize for efficiency
    prepareToggleList(data) {
      data.forEach((question) => {
        const status = this.checkQuestionToggleStatus(question.key)
        this.$set(this.questionToggleStatusList, question.key, status)
        // if dynamic question without answer but toggled on, add it to questions list (for reactive save button)
        if (question.conditions && !question.answer && status) this.$set(this.answers, question.key, null)
        // remove not toggled on dynamic question with default answer from answers list
        if (question.conditions && question.answer && !status) this.$delete(this.answers, question.key)
      })
    },
    // TODO: need another approach to include AND/OR conditions
    // TODO: need to optimize for efficiency
    addDependenciesToList(key, conditions) {
      conditions.forEach((condition) => {
        if (this.dependenciesList[condition.key]) return this.dependenciesList[condition.key].push(key)
        this.dependenciesList[condition.key] = [key]
      })
    },
    // return answers in String, unless type is multi_select, then return array
    getAnswersFromQuestion(question) {
      const savedAnswers = JSON.parse(localStorage.getItem('suitabilityAnswers'))
      const answer = (this.type === 'suitability' && savedAnswers) ? savedAnswers[question.key] : question.answer
      if (question.type === 'multi_select' || !Array.isArray(answer)) return answer
      return answer[0]
    },
    onInput(value, key) {
      this.answers[key] = value.toString()
      this.onValueChange()
    },
    onValueChange(question) {
      this.onChange(this.answers)
      if (this.dependenciesList[question.key]) this.checkDependencies(question.key)
    },
    // O(d * c) where d is number of dependencies, c is number of conditions
    // this method is on user input, not on initial render
    // TODO: need another approach to include AND/OR conditions
    // TODO: need to optimize for efficiency
    checkDependencies(questionKey) {
      this.dependenciesList[questionKey].forEach((dependency) => {
        const status = this.checkQuestionToggleStatus(dependency)
        this.questionToggleStatusList[dependency] = status
        // add/remove dynamic question's answers if toggled on/off
        const question = this.questions.find((question) => question.key === dependency)
        status ?
          this.$set(this.answers, dependency, this.getAnswersFromQuestion(question)) :
          this.$delete(this.answers, dependency)
      })
    },
    // given question key, return toggle on (true) or off (false)
    // O(c) where c is number of conditions
    // TODO: need another approach to include AND/OR conditions
    // TODO: need to optimize for efficiency
    checkQuestionToggleStatus(questionKey) {
      const question = this.questions.find((question) => question.key === questionKey)
      if (!question.conditions) return true
      let toggleOn = true

      if (question.relation === 'OR') {
        toggleOn = question.conditions.some((condition) =>
          this.answers[condition.key] && // 1. if dependant question is answered
          condition.answers.includes(this.answers[condition.key]) // 2. if condition is selected in the dependant question
        )
      } else {
        // if any of the 2 criteria not match, toggle off
        toggleOn = !question.conditions.some((condition) =>
          !this.answers[condition.key] || // 1. if dependant question is not answered yet
          !condition.answers.includes(this.answers[condition.key]) // 2. if condition is not selected in the dependant question
        )
      }
      return toggleOn
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
@import "@assets/style/addy-variables.scss";

.each-question {
  .question-name {
    font-size: 16px;
    .tooltip-info {
      width: 13px;
    }
  }
  .switch {
    min-width: 60px;
    max-width: 60px;
  }
}
</style>
